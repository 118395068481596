<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <v-col sm="8" md="8" lg="8">
          <v-layout align-center fill-height>
            {{
              $t("This address for") +
              " " +
              validation.Customer.FirstName +
              " " +
              validation.Customer.LastName +
              " " +
              $t("is not complete and should be entered in IPRO.")
            }}
          </v-layout>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <p
        class="mb-0"
        :class="[validation.ToAddress.Country == '' ? 'font-weight-bold' : '']"
      >
        {{ $t("Country") + ": " + validation.ToAddress.Country }}
      </p>
      <p
        class="mb-0"
        :class="[validation.ToAddress.City == '' ? 'font-weight-bold' : '']"
      >
        {{ $t("City") + ": " + validation.ToAddress.City }}
      </p>
      <p
        class="mb-0"
        :class="[validation.ToAddress.Zip == '' ? 'font-weight-bold' : '']"
      >
        {{ $t("Zip") + ": " + validation.ToAddress.Zip }}
      </p>
      <p
        class="mb-0"
        :class="[validation.ToAddress.Street == '' ? 'font-weight-bold' : '']"
      >
        {{ $t("Street and number") + ": " + validation.ToAddress.Street + " " }}
      </p>
    </v-card-subtitle>
    <v-card-text>
      <cardValdiationButtons
        :validation="validation"
        :needsCancelButton="false"
        ignoreButtonName=""
        :successButtonName="$t('Ok')"
        @deleteOutArray="$emit('deleteOutArray',$event)"

      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["validation"],
};
</script>

<style>
</style>